import * as React from 'react';
function SvgScissor(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#scissor_svg__clip0_5639_2645)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2.068.75h1.864L5.25 2.068v1.864l-.052.052 5.94 5.941v.762H9.377L5.951 7.264l-.753.753.052.052v1.864L3.932 11.25H2.068L.75 9.932V8.068L2.068 6.75h1.864l.003.003L4.69 6l-.754-.753-.003.003H2.068L.75 3.932V2.068L2.068.75zm1.909 2.252a.975.975 0 11-1.95 0 .975.975 0 011.95 0zM7.727 5.5L6.45 4.237l2.925-2.925h1.775v.763L7.726 5.5zm-1.934 0h.415l.292.293v.414l-.292.293h-.415L5.5 6.207v-.414l.293-.293zM3.002 9.977a.975.975 0 100-1.95.975.975 0 000 1.95z'
                    fill='#53A4EB'
                />
            </g>
            <defs>
                <clipPath id='scissor_svg__clip0_5639_2645'>
                    <path fill='#fff' d='M0 0h12v12H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgScissor;
