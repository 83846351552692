import * as React from 'react';
function SvgWarningTriangle(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M.025 10.704L6 .356l5.974 10.348H.025zM6.5 7.5h-1V5.138h1V7.5zm-.106 1.294a.54.54 0 01-.788 0 .538.538 0 010-.781A.549.549 0 016 7.85a.55.55 0 01.394.163.54.54 0 010 .781z'
                fill='#373639'
            />
        </svg>
    );
}
export default SvgWarningTriangle;
