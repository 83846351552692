import { useDisclosure } from '@chakra-ui/react';
import { AlertDialog } from '@Components/AlertDialog';
import { FC, useEffect, useState, useCallback } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

export type BlockerFunction = (args: { currentLocation: Location; nextLocation: Location }) => boolean;

interface IProps {
    onLeave: () => void;
    dirty: boolean;
    title: string;
    message: string;
    submitLabel: string;
    cancelLabel: string;
}

export const Prompt: FC<IProps> = ({ title, message, submitLabel, cancelLabel, dirty, onLeave }) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [leaveClicked, setLeaveClicked] = useState(false);
    const [nextPath, setNextpath] = useState<string>();
    const navigate = useNavigate();
    useBlocker(handleBlock);

    function handleBlock({ currentLocation, nextLocation }): boolean {
        if (currentLocation.pathname !== nextLocation.pathname && dirty && !leaveClicked) {
            setNextpath(nextLocation.pathname);
            onOpen();
            return true;
        }
        return false;
    }

    const beforeUnload = useCallback(
        (e: BeforeUnloadEvent) => {
            if (dirty) {
                e.preventDefault();
                e.returnValue = '';
            }
        },
        [dirty]
    );

    useEffect(() => {
        addEventListener('beforeunload', beforeUnload, { capture: true });

        return () => {
            removeEventListener('beforeunload', beforeUnload, { capture: true });
        };
    }, []);

    return (
        isOpen && (
            <AlertDialog
                isOpen={isOpen}
                alertType='error'
                title={title}
                message={message}
                submitLabel={submitLabel}
                submitHandle={() => {
                    onLeave();
                    setLeaveClicked(true);
                    setTimeout(() => navigate(nextPath), 1);
                }}
                cancelButtonLabel={cancelLabel}
                onClose={onClose}
            />
        )
    );
};
