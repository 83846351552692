import * as React from 'react';
function SvgSelection(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M.713 9.075V7.5h4.675v1.575H.713zm0-4.575V2.925h4.675V4.5H.713zM7.2 10.688l-1.1-1.1 1.3-1.3-1.3-1.3 1.1-1.1 1.3 1.3 1.3-1.3 1.1 1.1-1.3 1.3 1.3 1.3-1.1 1.1-1.3-1.3-1.3 1.3zm1.25-5.075L6.275 3.438l1.1-1.1L8.438 3.4l1.925-1.95 1.112 1.125L8.45 5.613z'
                fill='#53A4EB'
            />
        </svg>
    );
}
export default SvgSelection;
