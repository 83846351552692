import parse from 'html-react-parser';
import { Box, Flex, Text, BoxProps, FlexProps } from '@chakra-ui/react';
import Spinner from '@Components/Spinner';
import {
    Consent,
    PaymentAlt,
    Safety,
    SchoolAlt,
    Scissor,
    Selection,
    SquareOutline,
    WarningSquare,
    WarningTriangle,
} from '@Icon';
import { useLazyGetEventDisclaimerQuery, useLazyGetEventDetailsQuery } from '@Redux/services/Event';
import { useGetOrganizedByQuery } from '@Redux/services/LookupApi';
import { format } from 'date-fns';
import { isString, range } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function getOptions(costOption) {
    const tuppleList = range(1, 16).map((i) => ({
        value: costOption?.[`option${i}`],
        group: costOption?.[`option${i}Group`],
    }));
    return {
        option1: tuppleList.filter((i) => i.group === 951030001),
        option2: tuppleList.filter((i) => i.group === 951030002),
        others: tuppleList.filter((i) => i.group === 0 && i.value !== null),
    };
}

type BannerProps = {
    label: string;
    icon: any;
} & FlexProps;

const Banner: FC<BannerProps> = ({ icon, label, ...rest }) => {
    return (
        <Flex flexDirection={'row'} alignItems={'center'} {...rest}>
            <Box mr={2} p={1}>
                {icon}
            </Box>
            <Text color={'text-primary'} fontWeight={600} fontSize={'9pt'} as={'div'}>
                {label}
            </Text>
        </Flex>
    );
};

const SectionHeader = ({ icon, label }) => {
    return (
        <Flex flexDirection={'row'} alignItems={'center'} py={2}>
            <Box mr={2}>{icon}</Box>
            <Text color={'text-primary'} fontWeight={600} fontSize={'9pt'} as={'div'}>
                {label}
            </Text>
        </Flex>
    );
};

type SignatureBoxProps = {
    label: string;
} & BoxProps;

const SignatureBox: FC<SignatureBoxProps> = ({ label, ...rest }) => {
    return (
        <Box
            flexBasis={'50%'}
            flexGrow={1}
            height={'46pt'}
            border={'1pt solid #A0AEC0'}
            backgroundColor={'#ffffff'}
            {...rest}
        >
            <Text color={'text-primary'} fontWeight={500} fontSize={'7pt'} m={2}>
                {label}
            </Text>
        </Box>
    );
};

type SelectionItemProps = {
    label: string;
    isCheckbox?: boolean;
} & FlexProps;

const SelectionItem: FC<SelectionItemProps> = ({ label, isCheckbox = false, ...rest }) => {
    return (
        <Flex flexBasis={'33.33%'} {...rest}>
            <Box
                width={3}
                height={3}
                backgroundColor={'#ffffff'}
                borderRadius={isCheckbox ? 0 : 10}
                border={'1pt solid #A0AEC0'}
                mr={1}
            />
            <Text color={'text-primary'} fontWeight={500} fontSize={'7pt'}>
                {label}
            </Text>
        </Flex>
    );
};

type FlexInputProps = {
    optionalLabel?: string;
    label: string;
} & FlexProps;

const FlexInput: FC<FlexInputProps> = ({ label, optionalLabel, ...rest }) => {
    return (
        <Flex flexDirection={'row'} flexBasis={'50%'} height={'25pt'} borderBottom={'1pt solid #A0AEC0'} {...rest}>
            <Box alignContent={'flex-end'} pb={1} pr={2}>
                <Text color={'text-primary'} fontWeight={500} fontSize={'7pt'}>
                    {label}{' '}
                    {optionalLabel && (
                        <Text fontWeight={300} as={'span'}>
                            ({optionalLabel})
                        </Text>
                    )}
                </Text>
            </Box>
            <Box backgroundColor={'#ffffff'} border={'1pt solid #A0AEC0'} borderBottomWidth={0} flexGrow={1} />
        </Flex>
    );
};

type EventQuestionProps = {
    label: string;
    isYesNoQuestion?: boolean;
} & FlexProps;

const EventQuestion: FC<EventQuestionProps> = ({ label, isYesNoQuestion, ...rest }) => {
    const { t } = useTranslation();
    return (
        <Flex alignItems={'flex-end'} style={{ pageBreakInside: 'avoid' }} {...rest}>
            <Text color={'text-primary'} fontWeight={500} fontSize={'8pt'} py={2}>
                {label}
            </Text>
            {isYesNoQuestion && (
                <Flex direction={'row'} py={2} pl={2}>
                    <SelectionItem label={t('EventPrintForm.yes')} isCheckbox mr={4} />
                    <SelectionItem label={t('EventPrintForm.no')} isCheckbox />
                </Flex>
            )}
        </Flex>
    );
};

type CostItemProps = {
    showSectionHeader: boolean;
    sectionHeaderLabel?: string;
    data: any;
    nonZeroQuantity: boolean;
    icon: any;
} & FlexProps;

const CostItem: FC<CostItemProps> = ({
    showSectionHeader,
    sectionHeaderLabel,
    data,
    nonZeroQuantity,
    icon,
    ...rest
}) => {
    return (
        <Box style={{ pageBreakInside: 'avoid' }} {...rest}>
            {showSectionHeader && <SectionHeader icon={icon} label={sectionHeaderLabel} />}
            <Box backgroundColor={'#E2F2FC'} p={2} border={'1pt solid #7DC1F4'} mt={showSectionHeader ? 0 : -1}>
                <Flex flexDirection={'row'}>
                    <Box flexBasis={'25%'} pr={2}>
                        <Text color={'text-primary'} fontWeight={500} fontSize={'7pt'}>
                            Item name
                        </Text>
                        <Text color={'text-primary'} fontWeight={700} fontSize={'8pt'}>
                            {data.title}
                        </Text>
                    </Box>
                    <Box flexBasis={'25%'} pr={2}>
                        <Text color={'text-primary'} fontWeight={500} fontSize={'7pt'}>
                            Unit price
                        </Text>
                        <Text color={'text-primary'} fontWeight={700} fontSize={'8pt'}>
                            ${data.unitCost?.toFixed(2)}
                        </Text>
                    </Box>
                    <Flex flexBasis={'50%'} flexDirection={'row'} mb={2}>
                        <FlexInput
                            label={'Quantity'}
                            optionalLabel={
                                nonZeroQuantity
                                    ? `1${data.maxQuantityAllowed !== 1 ? `- ${data.maxQuantityAllowed}` : ''} max`
                                    : `0 - ${data.maxQuantityAllowed} max`
                            }
                            mr={2}
                        />
                        <FlexInput label={'Total cost'} mr={2} />
                    </Flex>
                </Flex>

                {data.options.option1.length > 0 && (
                    <Flex py={2}>
                        <Box flexBasis={'25%'}>
                            <Text color={'text-primary'} fontWeight={600} fontSize={'7pt'}>
                                Option 1: Select one only
                            </Text>
                        </Box>
                        <Flex flexBasis={'75%'} flexWrap={'wrap'}>
                            {data.options.option1.map((j) => (
                                <SelectionItem label={j.value} />
                            ))}
                        </Flex>
                    </Flex>
                )}
                {data.options.option2.length > 0 && (
                    <Flex py={2} borderTop={data.options.option1.length > 0 ? '1px dotted #7DC1F4' : ''}>
                        <Box flexBasis={'25%'}>
                            <Text color={'text-primary'} fontWeight={600} fontSize={'7pt'}>
                                Option 2: Select one only
                            </Text>
                        </Box>
                        <Flex flexBasis={'75%'} flexWrap={'wrap'}>
                            {data.options.option2.map((j) => (
                                <SelectionItem label={j.value} />
                            ))}
                        </Flex>
                    </Flex>
                )}
                {data.options.others.length > 0 && (
                    <Flex
                        py={2}
                        borderTop={
                            data.options.option1.length > 0 || data.options.option2.length > 0
                                ? '1px dotted #7DC1F4'
                                : ''
                        }
                    >
                        <Box flexBasis={'25%'}>
                            <Text color={'text-primary'} fontWeight={600} fontSize={'7pt'}>
                                Other options: Select any
                            </Text>
                        </Box>
                        <Flex flexBasis={'75%'} flexWrap={'wrap'}>
                            {data.options.others.map((j) => (
                                <SelectionItem label={j.value} isCheckbox />
                            ))}
                        </Flex>
                    </Flex>
                )}
            </Box>
        </Box>
    );
};

const EventDetailsPage = () => {
    const { id } = useParams();
    if (!id) {
        return;
    }

    const { t } = useTranslation();
    const [getData] = useLazyGetEventDetailsQuery();
    const [getDisclaimerData] = useLazyGetEventDisclaimerQuery();
    const [data, setData] = useState<any>();
    const [disclaimerText, setDisclaimerText] = useState<any>();
    const { data: { data: organizedBy = [] } = {} } = useGetOrganizedByQuery();
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [dueDate, setDueDate] = useState<Date>();
    const [isSingleDay, setIsSingleDay] = useState(false);
    const [requiredCostItems, setRequiredCostItems] = useState([]);
    const [requiredOneCostItems, setRequiredOneCostItems] = useState([]);
    const [requiredAnyCostItems, setRequiredAnyCostItems] = useState([]);

    const fetchData = async () => {
        const { data } = await getData({ id }).unwrap();
        setData(data);
        const { data: disclaimerData } = await getDisclaimerData({ id }).unwrap();
        // not sure why replace EVENTNAME in mfr when API should be doing all the work, keeping it just incase
        const text = disclaimerData?.ucdsbDisclaimerText?.replace('<EVENTNAME>', data?.eventName) || '';
        setDisclaimerText(isString(text) && text !== '' ? parse(text) : '');
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!data) return;
        setStartDate(new Date(data.startDate));
        setEndDate(new Date(data.endDate));
        setDueDate(new Date(data.dueDate));
        setIsSingleDay(format(new Date(data.startDate), 'yyyyMMdd') === format(new Date(data.endDate), 'yyyyMMdd'));
        setRequiredCostItems(
            data.costItems.costItemsInfo
                .filter((i) => i.required === 951030000)
                .map((i) => ({ ...i, options: getOptions(i) }))
        );
        setRequiredOneCostItems(
            data.costItems.costItemsInfo
                .filter((i) => i.required === 951030005)
                .map((i) => ({ ...i, options: getOptions(i) }))
        );
        setRequiredAnyCostItems(
            data.costItems.costItemsInfo
                .filter((i) => i.required === 951030010)
                .map((i) => ({ ...i, options: getOptions(i) }))
        );
        document.title = data.eventCode;
    }, [data]);

    if (!data)
        return (
            <>
                <Box mt={8}>
                    <Spinner />
                </Box>
            </>
        );
    return (
        <Box id='print-container' maxWidth={800} margin={'auto'}>
            <Flex flexDirection={'row'} justifyContent={'space-between'} mb={2}>
                <Flex flexDirection={'column'} mb={1} flexGrow={1}>
                    <Text color={'gray.500'} fontWeight={500} fontSize={'7pt'}>
                        {data.team}{' '}
                        <Text as='span' fontWeight={400}>
                            •
                        </Text>{' '}
                        <Text as='span' fontWeight={700}>
                            {t('EventPrintForm.title')} • {data.eventCode}
                        </Text>
                    </Text>
                    <Text color={'text-primary'} fontWeight={700} fontSize={'20pt'} lineHeight={'20pt'} mb={2}>
                        {data.name}
                    </Text>
                    <Text color={'text-primary'} fontWeight={700} fontSize={'8pt'} maxWidth={'75%'}>
                        {t('EventPrintForm.organizedBy')}{' '}
                        {organizedBy.find((i) => i.key === data.organizedById)?.value || ''}
                    </Text>
                </Flex>
                <Flex flexDirection={'column'} alignItems={'flex-end'} justifyContent={'flex-end'} flexShrink={0}>
                    <SchoolAlt width={83} height={47} />
                </Flex>
            </Flex>
            <Box mb={3}>
                <Flex direction={'row'} flexWrap={'wrap'} px={4} py={2} backgroundColor={'gray.200'} mb={1}>
                    <Flex flexBasis={'50%'}>
                        <Text color={'text-primary'} fontWeight={700} fontSize={'8pt'} as={'div'}>
                            {t('EventPrintForm.eventDate')}{' '}
                            <Text as={'span'} fontWeight={400}>
                                {startDate &&
                                    endDate &&
                                    `${format(startDate, 'E MMM dd, yyyy')} ${format(startDate, 'hh:mm aaa')} -${
                                        !isSingleDay ? ` ${format(endDate, 'E MMM dd, yyyy')}` : ''
                                    } ${format(endDate, 'hh:mm aaa')}`}
                            </Text>
                        </Text>
                    </Flex>

                    <Flex flexBasis={'50%'}>
                        <Text color={'text-primary'} fontWeight={700} fontSize={'8pt'} as={'div'}>
                            {t('EventPrintForm.replyBy')}{' '}
                            <Text as={'span'} fontWeight={400}>
                                {dueDate && format(dueDate, 'E MMM dd, yyyy')}
                            </Text>
                        </Text>
                    </Flex>
                </Flex>

                {!data.isRefundable && (
                    <Banner
                        icon={<WarningTriangle width={12} height={12} />}
                        label={t('EventPrintForm.nonRefundableMessage')}
                        backgroundColor={'#EFC06E'}
                        p={1}
                        mb={1}
                    />
                )}
                <Banner
                    icon={<WarningSquare width={12} height={12} />}
                    label={t('EventPrintForm.requiredFieldMessage')}
                    backgroundColor={'#AAD9F7'}
                    p={1}
                />
            </Box>
            <Flex flexDirection={'row'} mb={2}>
                <SignatureBox label={t('EventPrintForm.studentName')} />
                <SignatureBox label={t('EventPrintForm.teacherName')} borderLeft={0} />
            </Flex>

            <Box maxWidth={'75%'}>
                <Text color={'text-primary'} fontWeight={500} fontSize={'7pt'} style={{ whiteSpace: 'pre-line' }}>
                    {data.offerDescription}
                </Text>
            </Box>

            {requiredCostItems.length > 0 && (
                <Box>
                    {requiredCostItems.map((i, index) => (
                        <CostItem
                            sectionHeaderLabel={t('EventPrintForm.makeYourSelection')}
                            showSectionHeader={index === 0}
                            data={i}
                            nonZeroQuantity={true}
                            icon={<Scissor width={12} height={12} />}
                        />
                    ))}
                </Box>
            )}

            {requiredOneCostItems.length > 0 && (
                <Box>
                    {requiredOneCostItems.map((i, index) => (
                        <CostItem
                            sectionHeaderLabel={t('EventPrintForm.selectOneOrMore')}
                            showSectionHeader={index === 0}
                            data={i}
                            nonZeroQuantity={false}
                            icon={<Selection width={12} height={12} />}
                        />
                    ))}
                </Box>
            )}

            {requiredAnyCostItems.length > 0 && (
                <Box>
                    {requiredAnyCostItems.map((i, index) => (
                        <CostItem
                            sectionHeaderLabel={t('EventPrintForm.selectAny')}
                            showSectionHeader={index === 0}
                            data={i}
                            nonZeroQuantity={false}
                            icon={<SquareOutline width={12} height={12} />}
                        />
                    ))}
                </Box>
            )}

            {(requiredCostItems.length > 0 || requiredOneCostItems.length > 0 || requiredAnyCostItems.length > 0) && (
                <Box
                    backgroundColor={'#7DC1F4'}
                    p={2}
                    border={'1pt solid #7DC1F4'}
                    style={{ pageBreakInside: 'avoid' }}
                >
                    <Flex flexDirection={'row'}>
                        <Flex flexBasis={'75%'} pr={2}>
                            <Box mr={2}>
                                <PaymentAlt />
                            </Box>
                            <Text color={'text-primary'} fontWeight={700} fontSize={'8pt'}>
                                {t('EventPrintForm.grandTotal')}
                            </Text>
                        </Flex>
                        <Box flexBasis={'25%'} pr={2}>
                            <SignatureBox label={t('EventPrintForm.totalPrice')} height={'35pt'} />
                        </Box>
                    </Flex>
                </Box>
            )}

            {data.eventQuestions.questions.map((i) => (
                <Box style={{ pageBreakInside: 'avoid' }}>
                    <EventQuestion label={i.text} isYesNoQuestion={i.yesNoTypeOfQuestion} mb={1} />
                    {i.yesNoTypeOfQuestion &&
                        ((i.isCommentRequired && !i.isCommentRequiredNo) ||
                            (!i.isCommentRequired && i.isCommentRequiredNo)) && (
                            <Flex flexDirection={'row'} mb={2}>
                                {i.isCommentRequired && (
                                    <SignatureBox label={t('EventPrintForm.questionYesElaborate')} />
                                )}
                                {i.isCommentRequiredNo && (
                                    <SignatureBox label={t('EventPrintForm.questionNoElaborate')} />
                                )}
                            </Flex>
                        )}
                    {(!i.yesNoTypeOfQuestion || (i.isCommentRequiredNo && i.isCommentRequired)) && (
                        <Flex flexDirection={'row'} mb={2}>
                            <SignatureBox label={t('EventPrintForm.questionOtherElaborate')} />
                        </Flex>
                    )}
                </Box>
            ))}

            <Box style={{ pageBreakInside: 'avoid' }}>
                <SectionHeader icon={<Safety />} label={t('EventPrintForm.safetyTitle')} />
                <Text color={'text-primary'} fontWeight={500} fontSize={'8pt'} py={2}>
                    {t('EventPrintForm.safetyDescription')}
                </Text>
                <Flex flexDirection={'row'} mb={2}>
                    <SignatureBox label={t('EventPrintForm.lifeThreatening')} />
                    <SignatureBox label={t('EventPrintForm.nonLifeThreatening')} borderLeft={0} />
                </Flex>
            </Box>

            <Box style={{ pageBreakInside: 'avoid' }}>
                {disclaimerText && (
                    <>
                        <SectionHeader icon={<Consent />} label={t('EventPrintForm.consentTitle')} />
                        <Text
                            color={'text-primary'}
                            fontWeight={500}
                            fontSize={'7pt'}
                            py={2}
                            maxWidth={'83.33%'}
                            whiteSpace='pre-line'
                        >
                            {disclaimerText}
                        </Text>
                    </>
                )}
                <Text color={'text-primary'} fontWeight={500} fontSize={'8pt'} py={2}>
                    {t('EventPrintForm.consentAgree')}
                </Text>
                <Flex flexDirection={'row'} mb={1}>
                    <SignatureBox label={t('EventPrintForm.guardianName')} />
                    <SignatureBox label={t('EventPrintForm.guardianSignature')} borderLeft={0} />
                    <SignatureBox label={t('EventPrintForm.signatureDate')} borderLeft={0} />
                </Flex>
                <Text color={'text-primary'} fontWeight={500} fontSize={'6pt'}>
                    {t('EventPrintForm.signatureHelp')}
                </Text>
            </Box>
        </Box>
    );
};

export default EventDetailsPage;
