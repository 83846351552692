import * as React from 'react';
function SvgSquareOutline(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M.7 11.3V9.725h1.575V11.3H.7zm0-2.575v-1.15h1.575v1.15H.7zm0-2.15v-1.15h1.575v1.15H.7zm0-2.15v-1.15h1.575v1.15H.7zm0-2.15V.7h1.575v1.575H.7zM3.275 11.3V9.725h1.15V11.3h-1.15zm0-9.025V.7h1.15v1.575h-1.15zm2.15 9.025V9.725h1.15V11.3h-1.15zm0-9.025V.7h1.15v1.575h-1.15zm2.15 9.025V9.725h1.15V11.3h-1.15zm0-9.025V.7h1.15v1.575h-1.15zm2.15 9.025V9.725H11.3V11.3H9.725zm0-2.575v-1.15H11.3v1.15H9.725zm0-2.15v-1.15H11.3v1.15H9.725zm0-2.15v-1.15H11.3v1.15H9.725zm0-2.15V.7H11.3v1.575H9.725z'
                fill='#53A4EB'
            />
        </svg>
    );
}
export default SvgSquareOutline;
