import * as React from 'react';
function SvgSchoolAlt(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 84 47' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M78.525 6.827H68.404l-5.06 8.911 5.06 8.911h10.121l5.06-8.911-5.06-8.911z' fill='#73CC49' />
            <path fill='#373639' d='M72.798 15.735h1.383v24.554h-1.383z' />
            <path d='M5.42 13.315H16.44l4.286 12.45H1.134l4.286-12.45z' fill='#73CC49' />
            <path fill='#373639' d='M10.375 19.54h1.383v20.75h-1.383z' />
            <path d='M41.5 26.415l-12.911-7.96L41.5 10.498l12.911 7.959-12.911 7.96z' fill='#72BEFB' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M26.63 22.203H61.212v18.42H44.922v-8.306h-6.908v8.306H21.789v-18.42h4.842zm1.658 8.137l3.513-2.553 3.513 2.553-1.342 4.13H29.63l-1.342-4.13zm22.88-2.553l-3.514 2.553 1.342 4.13h4.343l1.342-4.13-3.514-2.553z'
                fill='#EEAB00'
            />
            <path d='M49.973 8.559l-3.945 6.832h7.89l-3.945-6.832z' fill='#A28AC7' />
            <path d='M67.488 24.118h-5.824v9.558h5.824v-9.558z' fill='#5DA638' />
            <path
                fill='#373639'
                d='M63.979 28.877h1.383v11.412h-1.383zM62.596 40.29H83v.346H62.596zM0 40.29h20.404v.346H0z'
            />
        </svg>
    );
}
export default SvgSchoolAlt;
