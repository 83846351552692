import * as React from 'react';
function SvgWarningSquare(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M.4.4h11.224v11.225H.4V.4zm6.287 5.1v3.188H5.312V5.5h1.375zm-.15-.969A.717.717 0 016 4.75a.722.722 0 01-.531-.219.722.722 0 01-.22-.531c0-.217.074-.396.22-.537a.733.733 0 01.53-.213.729.729 0 01.75.75c0 .208-.07.385-.212.531z'
                fill='#171717'
            />
        </svg>
    );
}
export default SvgWarningSquare;
