import * as React from 'react';
function SvgPaymentAlt(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#payment-alt_svg__clip0_5710_3139)'>
                <mask
                    id='payment-alt_svg__a'
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits='userSpaceOnUse'
                    x={0}
                    y={0}
                    width={12}
                    height={12}
                >
                    <path fill='#D9D9D9' d='M0 0h12v12H0z' />
                </mask>
                <g mask='url(#payment-alt_svg__a)'>
                    <path
                        d='M7.187 6.113c.417 0 .771-.146 1.063-.438.291-.292.437-.646.437-1.062 0-.417-.146-.771-.437-1.063a1.447 1.447 0 00-1.063-.437c-.416 0-.77.145-1.062.437a1.447 1.447 0 00-.438 1.063c0 .416.146.77.438 1.062.291.292.646.438 1.062.438zM2.5 8V1.238h9.387V8H2.5zM.112 10.387V3.5h1.575v5.313H10v1.574H.112zm3.775-6.774c.275 0 .51-.098.707-.294a.963.963 0 00.293-.706h-1v1zm6.613 0v-1h-1c0 .275.098.51.293.706a.963.963 0 00.707.294zm-1 3h1v-1a.964.964 0 00-.707.293.964.964 0 00-.293.707zm-5.613 0h1a.964.964 0 00-.293-.707.964.964 0 00-.707-.293v1z'
                        fill='#1C1B1F'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='payment-alt_svg__clip0_5710_3139'>
                    <path fill='#fff' d='M0 0h12v12H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgPaymentAlt;
