import * as React from 'react';
function SvgConsent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.467 7.662l-5.442 3.955L.582 7.662l2.079-6.397h6.727l2.079 6.397zM6.75 7.75h-1.5V6.5H4V5h1.25V3.75h1.5V5H8v1.5H6.75v1.25z'
                fill='#D96166'
            />
        </svg>
    );
}
export default SvgConsent;
